<template>
  <section>
    <div class="cs-height_70 cs-height_lg_40"></div>
    <hooper :settings="hooperSettings">
      <slide>
        <div >
          <a href="#" class="cs-image_box">
            <div class="cs-image_box_in">
              <div class="cs-image_box_img"><img :src="require(`@/assets/img/nft/gold.png`)" alt="Product"></div>
            </div>
          </a>
        </div>
      </slide>
      <slide>
        <div>
          <a href="#" class="cs-image_box">
            <div class="cs-image_box_in">
              <div class="cs-image_box_img"><img :src="require(`@/assets/img/nft/diamond.png`)" alt="Product"></div>
            </div>
          </a>
        </div>
      </slide>
      <slide>
        <div>
          <a href="#" class="cs-image_box">
            <div class="cs-image_box_in">
              <div class="cs-image_box_img"><img :src="require(`@/assets/img/nft/emerald.png`)" alt="Product"></div>
            </div>
          </a>
        </div>
      </slide>
      <hooper-pagination v-if="isMobile" slot="hooper-addons" class="trendItemPagination"></hooper-pagination>
    </hooper>
  </section>
</template>

<script>
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import "hooper/dist/hooper.css";

export default {
  name: "TrendingItem",
  components: {
    Hooper,
    Slide,
    HooperPagination
  },
  computed: {
    isMobile() {
        return this.$q.screen.width < 768;
    },
  },
  data() {
    return {
      hooperSettingsInPc: {
          itemsToShow: 3.5,
          itemsToSlide: 1,
          initialSlide: 1,
          infiniteScroll: true,
          centerMode: true,
          autoPlay: true,
          playSpeed: 3500,
          transition: 1000
      },
      hooperSettingsInMobile: {
          itemsToShow: 1,
          itemsToSlide: 1,
          infiniteScroll: true,
          centerMode: true,
          autoPlay: true,
          playSpeed: 3000,
          transition: 1000
      },
      hooperSettings: null
    };
  },
  created () {
    if (this.isMobile)
      this.hooperSettings = this.hooperSettingsInMobile
    else
      this.hooperSettings = this.hooperSettingsInPc
  },
  methods: {
    onMouseMove() {
      if (!this.mouseDown || this.dragging) {
        return;
      }

      this.dragging = true;
    },
  },
};
</script>

<style scoped>
  .hooper {
    height:100%;
  }

  .hooper >>> .hooper-navigation button {
    background: white;
  }

  .hooper >>> .hooper-navigation .is-disabled {
    display: none;
  }

  .trendItemPagination {
      margin-bottom: -50px;
    }

  .hooper >>> .hooper-pagination .hooper-indicator {
      height: 10px;
      width: 10px;
      border-radius: 58px;
      margin: 0 5px;
      cursor: pointer;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      background-color: #0052FF !important;
  }

  .hooper >>> .hooper-pagination .hooper-indicators .is-active {
      width:30px;
  }

  .cs-image_box_img img {
    padding:0 20px;
  }
</style>
