<template>
    <section id="roadmap">
    <div class="cs-height_70 cs-height_lg_40"></div>
    <div class="container">
      <div class="cs-seciton_heading cs-style1 text-uppercase text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
        <h3 class="cs-section_title cs-font_16 cs-font_14_sm cs-gradient_color">Our Goals</h3>
        <h2 class="cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm">Roadmap Make Unique</h2>
      </div>
      <div class="cs-height_50 cs-height_lg_30"></div>
      <div class="cs-accent_seperator_1 cs-accent_color wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.2s"></div>
      <div class="row wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
        <div class="col-xl-3 col-md-3 col-sm-6">
          <div class="cs-card cs-style2">
            <div class="cs-card_number cs-accent_bg cs-white_color cs-center cs-primary_font cs-font_22 cs-bold">
              1
              <div class="cs-card_tick">
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.899994 9.90957C0.899994 5.43606 4.52649 1.80957 8.99999 1.80957C13.4735 1.80957 17.1 5.43606 17.1 9.90957C17.1 14.3831 13.4735 18.0096 8.99999 18.0096C4.52649 18.0096 0.899994 14.3831 0.899994 9.90957Z" fill="#06182C" stroke="#0A2540" stroke-width="2"/>
                  <path d="M8.99999 1.80957C4.52649 1.80957 0.899994 5.43606 0.899994 9.90957C0.899994 14.3831 4.52649 18.0096 8.99999 18.0096C13.4735 18.0096 17.1 14.3831 17.1 9.90957C17.1 5.43606 13.4735 1.80957 8.99999 1.80957ZM12.4755 5.60348L14.1584 7.28636L8.90211 12.5437L7.2291 14.2157L5.54622 12.5328L3.84158 10.8271L5.51359 9.15512L7.21824 10.8608L12.4755 5.60348Z" fill="white"/>
                </svg>                                   
              </div>
            </div>
            <div class="cs-height_30 cs-height_lg_15"></div>
            <div class="cs-card_in cs-white_bg">
              <h3 class="cs-gradient_color cs-font_18 cs-font_16_sm cs-m0">Phase 1</h3>
              <div class="cs-height_5 cs-height_lg_5"></div>
              <h2 class="cs-font_22 cs-font_16_sm cs-m0">Launch the collection</h2>
              <div class="cs-height_10 cs-height_lg_10"></div>
              <p class="cs-m0">There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages.</p>
            </div>
          </div>
          <div class="cs-height_25 cs-height_lg_25"></div>
        </div>
        <div class="col-xl-3 col-md-3 col-sm-6">
          <div class="cs-card cs-style2">
            <div class="cs-card_number cs-accent_bg cs-white_color cs-center cs-primary_font cs-font_22 cs-bold">
              2
              <div class="cs-card_tick">
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.899994 9.90957C0.899994 5.43606 4.52649 1.80957 8.99999 1.80957C13.4735 1.80957 17.1 5.43606 17.1 9.90957C17.1 14.3831 13.4735 18.0096 8.99999 18.0096C4.52649 18.0096 0.899994 14.3831 0.899994 9.90957Z" fill="#06182C" stroke="#0A2540" stroke-width="2"/>
                  <path d="M8.99999 1.80957C4.52649 1.80957 0.899994 5.43606 0.899994 9.90957C0.899994 14.3831 4.52649 18.0096 8.99999 18.0096C13.4735 18.0096 17.1 14.3831 17.1 9.90957C17.1 5.43606 13.4735 1.80957 8.99999 1.80957ZM12.4755 5.60348L14.1584 7.28636L8.90211 12.5437L7.2291 14.2157L5.54622 12.5328L3.84158 10.8271L5.51359 9.15512L7.21824 10.8608L12.4755 5.60348Z" fill="white"/>
                </svg>                                    
              </div>
            </div>
            <div class="cs-height_30 cs-height_lg_15"></div>
            <div class="cs-card_in cs-white_bg">
              <h3 class="cs-gradient_color cs-font_18 cs-font_16_sm cs-m0">Phase 2</h3>
              <div class="cs-height_5 cs-height_lg_5"></div>
              <h2 class="cs-font_22 cs-font_16_sm cs-m0">Community Build</h2>
              <div class="cs-height_10 cs-height_lg_10"></div>
              <p class="cs-m0">There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages.</p>
            </div>
          </div>
          <div class="cs-height_25 cs-height_lg_25"></div>
        </div>
        <div class="col-xl-3 col-md-3 col-sm-6">
          <div class="cs-card cs-style2">
            <div class="cs-card_number cs-accent_bg cs-white_color cs-center cs-primary_font cs-font_22 cs-bold">
              3
              <div class="cs-card_tick">
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.899994 9.90957C0.899994 5.43606 4.52649 1.80957 8.99999 1.80957C13.4735 1.80957 17.1 5.43606 17.1 9.90957C17.1 14.3831 13.4735 18.0096 8.99999 18.0096C4.52649 18.0096 0.899994 14.3831 0.899994 9.90957Z" fill="#06182C" stroke="#0A2540" stroke-width="2"/>
                  <path d="M8.99999 1.80957C4.52649 1.80957 0.899994 5.43606 0.899994 9.90957C0.899994 14.3831 4.52649 18.0096 8.99999 18.0096C13.4735 18.0096 17.1 14.3831 17.1 9.90957C17.1 5.43606 13.4735 1.80957 8.99999 1.80957ZM12.4755 5.60348L14.1584 7.28636L8.90211 12.5437L7.2291 14.2157L5.54622 12.5328L3.84158 10.8271L5.51359 9.15512L7.21824 10.8608L12.4755 5.60348Z" fill="white"/>
                </svg>                                    
              </div>
            </div>
            <div class="cs-height_30 cs-height_lg_15"></div>
            <div class="cs-card_in cs-white_bg">
              <h3 class="cs-gradient_color cs-font_18 cs-font_16_sm cs-m0">Phase 3</h3>
              <div class="cs-height_5 cs-height_lg_5"></div>
              <h2 class="cs-font_22 cs-font_16_sm cs-m0">Public Services</h2>
              <div class="cs-height_10 cs-height_lg_10"></div>
              <p class="cs-m0">There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages.</p>
            </div>
          </div>
          <div class="cs-height_25 cs-height_lg_25"></div>
        </div>
        <div class="col-xl-3 col-md-3 col-sm-6">
          <div class="cs-card cs-style2">
            <div class="cs-card_number cs-accent_bg cs-white_color cs-center cs-primary_font cs-font_22 cs-bold">
              4
              <div class="cs-card_tick">
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.899994 9.90957C0.899994 5.43606 4.52649 1.80957 8.99999 1.80957C13.4735 1.80957 17.1 5.43606 17.1 9.90957C17.1 14.3831 13.4735 18.0096 8.99999 18.0096C4.52649 18.0096 0.899994 14.3831 0.899994 9.90957Z" fill="#06182C" stroke="#0A2540" stroke-width="2"/>
                  <path d="M8.99999 1.80957C4.52649 1.80957 0.899994 5.43606 0.899994 9.90957C0.899994 14.3831 4.52649 18.0096 8.99999 18.0096C13.4735 18.0096 17.1 14.3831 17.1 9.90957C17.1 5.43606 13.4735 1.80957 8.99999 1.80957ZM12.4755 5.60348L14.1584 7.28636L8.90211 12.5437L7.2291 14.2157L5.54622 12.5328L3.84158 10.8271L5.51359 9.15512L7.21824 10.8608L12.4755 5.60348Z" fill="white"/>
                </svg>                                
              </div>
            </div>
            <div class="cs-height_30 cs-height_lg_15"></div>
            <div class="cs-card_in cs-white_bg">
              <h3 class="cs-gradient_color cs-font_18 cs-font_16_sm cs-m0">Phase 4</h3>
              <div class="cs-height_5 cs-height_lg_5"></div>
              <h2 class="cs-font_22 cs-font_16_sm cs-m0">The Next Generation</h2>
              <div class="cs-height_10 cs-height_lg_10"></div>
              <p class="cs-m0">There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages.</p>
            </div>
          </div>
          <div class="cs-height_25 cs-height_lg_25"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Roadmap",
  data() {
    return {
    };
  },
  mounted() {},
  computed: {},
};
</script>
